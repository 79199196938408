import React, {useState, useCallback, useEffect} from 'react';
import {
    Input,
    Card,
    Pagination,
    Spin,
    Alert,
    Statistic,
    Row,
    Col,
    Tooltip, Button
} from 'antd';
import {
    SearchOutlined,
    FileSearchOutlined,
    InboxOutlined,
    LinkOutlined, UpOutlined,
} from '@ant-design/icons';
import ApiService from "../api/ApiService";

const { Search } = Input;

interface DocumentResult {
    id: string;
    documentId: string;
    url: string;
    context: string;
    createdAt: string;
}

interface SearchResults {
    totalResults: number;
    currentPage: number;
    totalPages: number;
    results: DocumentResult[];
}

const SearchComponent: React.FC = () => {
    const [searchResults, setSearchResults] = useState<SearchResults | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [keyword, setKeyword] = useState<string>('');
    const [showScrollButton, setShowScrollButton] = useState<boolean>(false);

    const handleSearch = useCallback(async (value: string, page: number = 1) => {
        if (!value) return;

        setLoading(true);
        setError(null);
        setSearchResults(null);
        setKeyword(value);

        try {
            const response = await ApiService.get('/documents/search', {
                params: {
                    keyword: value,
                    page: page,
                    pageSize: 10
                }
            });

            setSearchResults(response.data);
            setCurrentPage(page);
        } catch (err) {
            setError('Failed to search documents. Please try again.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, []);

    const handlePageChange = (page: number) => {
        if (keyword) {
            handleSearch(keyword, page);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowScrollButton(true);
            } else {
                setShowScrollButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="p-4 max-w-6xl">
            <Row gutter={16} justify={'start'} className="mb-6 max-w-2xl">
                <Col span={24}>
                    <Search
                        placeholder="Search documents..."
                        enterButton={<SearchOutlined />}
                        size="large"
                        onSearch={(value) => handleSearch(value)}
                        className="mb-4"
                        prefix={<FileSearchOutlined className="text-gray-400" />}
                        rootClassName={'py-5'}
                        allowClear
                        onClear={() => setSearchResults(null)}
                    />
                </Col>
            </Row>

            {searchResults && (
                <Row gutter={16} className="mb-6">
                    <Col xs={24} sm={8}>
                        <Card>
                            <Statistic
                                title="Total Results"
                                value={searchResults.totalResults}
                                prefix={<InboxOutlined />}
                            />
                        </Card>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Card>
                            <Statistic
                                title="Current Page"
                                value={searchResults.currentPage}
                                suffix={`/ ${searchResults.totalPages}`}
                            />
                        </Card>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Card>
                            <Statistic
                                title="Documents Found"
                                value={searchResults.results.length}
                            />
                        </Card>
                    </Col>
                </Row>
            )}

            {loading && (
                <div className="text-center my-8">
                    <Spin size="large" />
                </div>
            )}

            {error && (
                <Alert
                    message="Error"
                    description={error}
                    type="error"
                    showIcon
                    className="mb-4"
                />
            )}

            {searchResults && !loading && (
                <>
                    <div className="space-y-4 mb-6">
                        {searchResults.results.map((doc) => (
                            <Card
                                key={doc.id}
                                title={
                                    <div className="flex items-center justify-between">
                                        <span>Document {doc.documentId}</span>
                                        <Tooltip title="Open Source Document">
                                            <a
                                                href={doc.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-blue-600 hover:text-blue-800"
                                            >
                                                <LinkOutlined />
                                            </a>
                                        </Tooltip>
                                    </div>
                                }
                                extra={new Date(doc.createdAt).toLocaleDateString()}
                                hoverable
                            >
                                <div
                                    className="whitespace-pre-wrap break-words"
                                    dangerouslySetInnerHTML={{ __html: doc.context }}
                                />
                            </Card>
                        ))}
                    </div>

                    {searchResults.totalResults > 0 && (
                        <div className="flex justify-center">
                            <Pagination
                                current={searchResults.currentPage}
                                total={searchResults.totalResults}
                                pageSize={10}
                                showQuickJumper
                                showSizeChanger={false}
                                onChange={handlePageChange}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} documents`}
                            />
                        </div>
                    )}

                    {searchResults.totalResults === 0 && (
                        <div className="text-center text-gray-500">
                            No documents found matching your search.
                        </div>
                    )}


                    {showScrollButton && (
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<UpOutlined />}
                            size="large"
                            onClick={scrollToTop}
                            style={{ position: 'fixed', bottom: '50px', right: '50px', width: '45px' }}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default SearchComponent;