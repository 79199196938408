import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {Row, Spin, Button, message, notification} from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import AppLayout from './AppLayout';
import { verifyRegister } from '../api/AuthService';

const VerifyRegister: React.FC = () => {
    const { token } = useParams<{ token: string }>();
    const [user, setUser] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [verified, setVerified] = useState(false);
    const [error, setError] = useState<string | null>();
    const navigate = useNavigate();

    useEffect(() => {
        const verifyUser = async () => {
            setLoading(true);
            verifyRegister(token).then(response => {
                setVerified(true);
                notification.success({
                    message: 'Verification was successful!',
                    description: 'Registration verified successfully! You will be redirected to login page shortly...',
                    placement: 'topRight',
                    duration: 0,
                    style: {
                        width: 400,
                    },
                });
                const { email, username } = response.data;
                setUser({ email, username});
                setTimeout(()=> {
                    navigate('/login', { state: { email, username } });
                }, 9001);
            }).catch(() => {
                setError('Verification failed. Please try again.');
            }).finally(()=> {
                setLoading(false);
            })
        };

        if (token) {
            verifyUser();
        }
    }, [token, navigate]);

    return (
        <AppLayout>
            <Row justify="center" align="middle" className={'w-full'}>
                        {loading ? (
                            <Spin size={'large'} />
                        ) : verified ? (
                            <div className={'flex flex-col justify-center items-center min-w-96 p-10 bg-white shadow-md rounded-md'}>
                                <CheckCircleOutlined style={{ fontSize: 50, color: '#52c41a' }} />
                                <span className={'text-xl my-5'}>
                                    Verification Successful!
                                </span>
                                <Button rootClassName={'w-full'} type="primary" onClick={() => navigate('/login', { state: user })}>
                                    Go to Login
                                </Button>
                            </div>
                        ) : (
                            error &&
                            <div className={'flex flex-col justify-center items-center min-w-96 p-10 bg-white shadow-md rounded-md'}>
                                <p className={'w-96 text-red-400 text-base font-semibold'}>{error}</p>
                            </div>
                        )}
            </Row>
        </AppLayout>
    );
};

export default VerifyRegister;

