import React from 'react';
import {AuthProvider} from "./context/AuthContext";
import {ConfigProvider, theme} from "antd";

import './index.css';
import 'remixicon/fonts/remixicon.css';
import './styles/index.css';

import RouterIndex from "./routes";

const App = ()=>
    <ConfigProvider
        theme={{
            components: {
                Button: {
                    colorPrimary: 'rgba(61,44,216,0.38)',
                    algorithm: true,
                },
                Input: {
                    colorPrimary: '#352feb',
                    algorithm: true,
                }
            },
            algorithm: [theme.compactAlgorithm],
        }}
    >
        <RouterIndex />
    </ConfigProvider>

export default App;