import React from "react";

interface IAppLayout {
    children: any
}

const AppLayout = ({ children }: IAppLayout) => (
    <div className={'background'}>
            {children}
    </div>
);

export default AppLayout;