import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuthCookie, setAuthCookie } from '../api/UtilityService';
import { getUser } from "../api/AuthService";
import {useNavigate} from "react-router-dom";

interface AuthContextProps {
    isAuthenticated: boolean;
    login: (token: string, user?: any) => void;
    logout: () => void;
    user: any;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<any> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!getAuthCookie());
    const [user, setUser] = useState<any>(null);
    const navigate = useNavigate();

    const fetchUser = () => {
        getUser().then(response => setUser(response.data));
    };

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible' && isAuthenticated) {
                fetchUser();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    const login = (token: string) => {
        setAuthCookie(token); // Store token in cookies
        setIsAuthenticated(true); // Mark as authenticated
        fetchUser();
        navigate('/home');
    };

    const logout = () => {
        setAuthCookie('');
        setIsAuthenticated(false);
        navigate('/login');
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, user }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to access AuthContext
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
