import React, { useState } from 'react';
import { Input, Form, Button, Card, Row, Col, Image, notification } from 'antd';
import { forgotPassword } from '../api/AuthService'; // Create this API call for handling forgot password
import AppLayout from "./AppLayout";
import {Link} from "react-router-dom";

const ForgotPassword: React.FC = () => {
    const [error, setError] = useState<any>();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const handleBlur = (
        form: any, e: React.FocusEvent<HTMLInputElement>, fieldName: string | string[], lowerCase?: boolean
    ) => {
        const { value } = e.target;
        const trimmedValue = value.trim();
        form.setFields([{
            name: fieldName,
            value: lowerCase ? trimmedValue.toLowerCase() : trimmedValue,
        }]);
    };

    const validateEmail = (_: any, value: string) => {
        if (!value) {
            return Promise.reject('Email is required');
        }
        const trimmedValue = value.trim();
        const isEmail = /\S+@\S+\.\S+/.test(trimmedValue);

        if (isEmail) {
            return Promise.resolve();
        }
        return Promise.reject('Invalid Email');
    };

    const onFinish = async (values: any) => {
        setError(null);
        setLoading(true);
        forgotPassword(values.email).then((response) => {
            setLoading(false);
            setError(null);
            notification.success({
                message: 'Password Reset Email Sent',
                description: 'A password reset email has been sent to you. Please check your inbox. If you don\'t see it, check your spam folder.',
                placement: 'topRight',
                duration: 0,
                style: {
                    width: 400,
                },
            });
        }).catch((error) => {
            setError(error?.message ?? null);
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <AppLayout>
            <Card
                type={"inner"}
                size={"default"}
                bordered={false}
                className={'login-form'}
            >
                {!!error && <div className={'bg-red-400 rounded-md p-3 text-white text-lg mb-4'}>
                    {error}
                </div>}

                <Row>
                    <Col span={24} className={'flex justify-center items-center mb-5'}>
                        <Image className={'cursor-pointer'} preview={false} width={'5rem'} src={'https://i.imghippo.com/files/Afxm5768UQ.png'} />
                    </Col>
                </Row>
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[{ validator: validateEmail }]}
                    >
                        <Input
                            onBlur={(e) => handleBlur(form, e, "email", false)}
                            disabled={loading}
                        />
                    </Form.Item>
                    <div className={'flex flex-col items-end'}>
                        <Button rootClassName="w-full mt-5" type="primary" htmlType="submit" loading={loading}>
                            Submit
                        </Button>
                        <Link to={'/login'} className={'pt-4 text-right text-xs font-semibold w-auto'}>Click here to login.</Link>
                    </div>
                </Form>
            </Card>
        </AppLayout>
    );
};

export default ForgotPassword;
