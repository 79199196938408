import React from "react";

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ProtectedRoute from "./ProtectedRoute";


import {RouteNames} from "./routes";
import UtilityService from "../api/UtilityService";
import Login from "../components/Login";
import Register from "../components/Register";
import VerifyRegister from "../components/VerifyRegister";
import ForgotPassword from "../components/ForgotPassword";
import ResetPassword from "../components/ResetPassword";
import Home from "../pages/Home";
import {AuthProvider} from "../context/AuthContext";

const RouterIndex = () => {
    const loggedIn = !!UtilityService.getAuthCookie();
    return(
        <BrowserRouter>
            <AuthProvider>
            <Routes>
                <Route exact path={RouteNames.Login} element={<Login />} />
                <Route exact path={RouteNames.Register} element={<Register />} />
                <Route exact path={RouteNames.Verify} element={<VerifyRegister />} />
                <Route exact path={RouteNames.ForgotPassword} element={<ForgotPassword />} />
                <Route exact path={RouteNames.ResetPassword} element={<ResetPassword />} />

                <Route exact path={RouteNames.Home} element={
                    <ProtectedRoute loggedIn={loggedIn}>
                        <Home />
                    </ProtectedRoute>
                    } />
                {/*<Route exact path={RouteNames.Forbidden} element={<Forbidden />} />*/}
            </Routes>
                </AuthProvider>
        </BrowserRouter>
    )
}

export default RouterIndex;