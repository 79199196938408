import ApiService from "./ApiService";

export const register = (email: string, username: string, password: string, ) => {
    return ApiService.post(`/auth/register`, { email, username, password });
};

export const login = (email: string, password: string) => {
    return ApiService.post(`/auth/login`, { email, password });
};

export const verifyRegister = (token?: string) => {
    return ApiService.get(`/auth/verify/${token}`);
};

export const forgotPassword = (email: string) => {
    return ApiService.post(`/auth/forgot-password`, { email });
};

export const resetPassword = (token: string, newPassword: string) => {
    return ApiService.post(`/auth/reset-password`, { token, newPassword });
};

export const getUser = () => {
    return ApiService.get(`/users/me`);
};


