import React, { useState } from 'react';
import {Layout, Menu, Input, Button, Avatar, Row, Col, notification, Image} from 'antd';
import { SearchOutlined, UserOutlined, LogoutOutlined, HomeOutlined, DashboardOutlined, SettingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import SearchComponent from "../components/SearchComponent";

const { Header, Content, Sider } = Layout;

const HomePage: React.FC = () => {
    const [collapsed, setCollapsed] = useState(false);
    const { user, logout } = useAuth();
    const [searchValue, setSearchValue] = useState('');
    const navigate = useNavigate();

    const onCollapse = (collapsed: boolean) => {
        setCollapsed(collapsed);
    };

    const handleSearch = (value: string) => {
        setSearchValue(value);
        notification.info({
            message: 'Search',
            description: `You searched for: ${value}`,
        });
    };

    const handleLogout = () => {
        logout();
        notification.success({
            message: 'Logged out successfully',
        });
        navigate('/login');
    };

    return (
        <Layout className="min-h-screen">
            <Sider
                className={'flex flex-col justify-between'}
                collapsible
                collapsed={collapsed}
                onCollapse={onCollapse}
                theme="dark"
            >
                <Menu theme={'dark'} mode="inline" defaultSelectedKeys={['1']}>
                    <Menu.Item key="1" icon={<HomeOutlined />}>
                        Home
                    </Menu.Item>

                    <Menu.Item key="2" icon={<LogoutOutlined />} onClick={handleLogout}>
                        Log Out
                    </Menu.Item>
                </Menu>
            </Sider>

            <Layout>
                <Content className="m-1 sm:m-4">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <div className="p-6 bg-white rounded shadow">
                                {
                                    user?.username &&
                                    <p className={'leading-7'}>
                                        <span className={'text-base font-medium'}>Welcome, </span>
                                        <span className={'text-base font-semibold'}>{user?.username}</span>
                                    </p>
                                }
                                <div>
                                    <SearchComponent />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </Layout>
    );
};

export default HomePage;
