import React, {useEffect, useState} from 'react';
import { Input, Form, Button, Card, Row, Col, Image, FormInstance } from 'antd';
import { login } from '../api/AuthService';
import { useAuth } from '../context/AuthContext';
import AppLayout from "./AppLayout";
import {Link, useLocation, useNavigate} from "react-router-dom";

const Login: React.FC = () => {
    const [error, setError] = useState<any>();
    const [form] = Form.useForm();
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [loading, setLoading] = useState(false);
    const { login: loginContext } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const { email, username } = location.state || {};

    useEffect(() => {
        if (email) {
          return form.setFieldValue('emailUsername', email);
        }

        if (username) {
            return form.setFieldValue('emailUsername', username);
        }
    }, [email, username]);

    const handleBlur = (
        form: FormInstance, e: React.FocusEvent<HTMLInputElement, Element>, fieldName: string | string[], lowerCase?: boolean
    ) => {
        const { value } = e.target;
        const trimmedValue = value.trim();
        form.setFields([{
            name: fieldName,
            value: lowerCase ? trimmedValue.toLowerCase() : trimmedValue,
        }]);
    };

    const validateEmailOrUsername = (_: any, value: string) => {
        if (!value) {
            return Promise.reject('Email or Username is required');
        }
        const trimmedValue = value.trim();
        const isEmail = /\S+@\S+\.\S+/.test(trimmedValue);
        const isUsername = /^[a-zA-Z0-9_]+$/.test(trimmedValue);

        if (isEmail || isUsername) {
            return Promise.resolve();
        }
        return Promise.reject('Invalid Email or Username');
    };

    const onFinish = async (values: any) => {
        setError(null);
        setLoading(true);
            login(values.emailUsername, values.password).then(response => {
                loginContext(response.data);
                setLoading(false);
                setError(null);
            }).catch(error => {
                setError(error?.message ?? null);
                setLoading(false);
            }).finally(() => {
                setLoading(false);
            })
    };

    return (
        <AppLayout>
            <Card
                type={"inner"}
                size={"default"}
                bordered={false}
                className={'login-form'}
            >
                {!!error && <div className={'bg-red-400 rounded-md p-3 text-white text-lg mb-4'}>
                    {error}
                </div>}

                <Row>
                    <Col span={24} className={'flex justify-center items-center mb-5'}>
                        <Image
                            preview={false}
                            width={'5rem'}
                            className={'cursor-pointer'}
                            src={'https://i.imghippo.com/files/Afxm5768UQ.png'} />
                    </Col>
                </Row>
                <Form
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={() => setValidateOnChange(true)}
                    validateTrigger={validateOnChange ? "onChange" : "onSubmit"}
                    layout="vertical"
                >
                    <Form.Item
                        name="emailUsername"
                        label="Email or Username"
                        rules={[{ validator: validateEmailOrUsername }]}
                    >
                        <Input
                            onBlur={(e) => handleBlur(form, e, "emailUsername", false)}
                            disabled={loading}
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        labelCol={{span: 24}}
                        rules={[{required: true, message: "Password is required"}]}
                    >
                        <div className={'flex flex-col items-end'}>
                            <Input.Password
                                disabled={loading}
                                autoComplete="current-password"
                            />

                            <Link to={'/forgot/password'} className={'mt-2 text-right text-xs font-semibold'}>Forgot your password?</Link>
                        </div>
                    </Form.Item>

                    <Form.Item>
                        <div className={'flex flex-col items-end'}>
                            <Button rootClassName="w-full mt-5" type="primary" htmlType="submit" loading={loading}>
                                Log in
                            </Button>
                            <Link to={'/register'} className={'pt-4 text-right text-xs font-semibold'}>Don't have an account? Click here to register.</Link>
                        </div>
                    </Form.Item>
                </Form>
            </Card>
        </AppLayout>
    );
};

export default Login;
