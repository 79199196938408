import React, { useState, useEffect } from 'react';
import {Input, Form, Button, Card, notification, Col, Image, Row} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import { resetPassword } from '../api/AuthService';
import AppLayout from "./AppLayout";

const ResetPassword: React.FC = () => {
    const { token } = useParams<{ token: string }>();

    const [error, setError] = useState<any>();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            setError('Invalid or missing token');
        }
    }, [token]);

    const validatePassword = (_: any, value: string) => {
        if (!value) {
            return Promise.reject('Password is required');
        }
        return Promise.resolve();
    };

    const validateConfirmPassword = (_: any, value: string) => {
        if (!value) {
            return Promise.reject('Confirm Password is required');
        }
        if (value !== form.getFieldValue('password')) {
            return Promise.reject('Passwords do not match');
        }
        return Promise.resolve();
    };

    const onFinish = async (values: any) => {
        if (!token) {
            setError('No token found');
            return;
        }

        setError(null);
        setLoading(true);

        resetPassword(token, values.password).then((response) => {
            setLoading(false);
            setError(null);
            form.resetFields();
            notification.success({
                message: 'Password Reset Successful',
                description: 'Your password has been reset successfully. You can now login with your new password.',
                placement: 'topRight',
                duration: 0,
                style: {
                    width: 400,
                },
            });
            navigate('/login')
        }).catch((error) => {
            setError(error?.message ?? 'An error occurred');
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <AppLayout>
            <Card
                type={"inner"}
                size={"default"}
                bordered={false}
                className={'login-form'}
            >
                {!!error && <div className={'bg-red-400 rounded-md p-3 text-white text-lg mb-4'}>
                    {error}
                </div>}

                <Row>
                    <Col span={24} className={'flex justify-center items-center mb-5'}>
                        <Image
                            preview={false}
                            width={'5rem'}
                            className={'cursor-pointer'}
                            src={'https://i.imghippo.com/files/Afxm5768UQ.png'} />
                    </Col>
                </Row>

                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[{ validator: validatePassword }]}
                    >
                        <Input.Password
                            disabled={loading}
                            autoComplete="new-password"
                        />
                    </Form.Item>
                    <Form.Item
                        name="confirmPassword"
                        label="Confirm Password"
                        dependencies={['password']}
                        rules={[
                            { required: true, message: 'Please confirm your password!' },
                            { validator: validateConfirmPassword }
                        ]}
                    >
                        <Input.Password
                            disabled={loading}
                            autoComplete="new-password"
                        />
                    </Form.Item>

                    <div className={'flex flex-col'}>
                        <Button rootClassName="w-full mt-5" type="primary" htmlType="submit" loading={loading}>
                            Reset Password
                        </Button>
                    </div>
                </Form>
            </Card>
        </AppLayout>
    );
};

export default ResetPassword;
